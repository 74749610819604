import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import partnerData from './data.json';
import {MarkerClusterer} from "@googlemaps/markerclusterer";

const GoogleMap: FunctionComponent = () => {
    const ref = useRef<HTMLDivElement>(null)
    const [map, setMap] = useState<google.maps.Map>();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {
                center: {lat: 45.00894830161313, lng: 16.27430475579605},
                zoom: 7,
            }));
        }

        if (map) {
            const infoWindow = new google.maps.InfoWindow({
                content: '',
                disableAutoPan: false,
            });

            const markers = partnerData.map(partner => {
                const marker = new google.maps.Marker({
                    position: partner.location,
                });

                marker.addListener('click', () => {
                    infoWindow.setContent(`<h2>${partner.name}</h2><p>${partner.address}, ${partner.city}</p>`);
                    infoWindow.open(map, marker);
                });

                return marker;
            });

            new MarkerClusterer({
                map, markers
            });
        }
    }, [ref, map]);

    return <div ref={ref} style={{ height: '500px' }} />;
};

export default GoogleMap;