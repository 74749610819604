import React,{FunctionComponent} from 'react';
import {Wrapper,Status} from '@googlemaps/react-wrapper';
import GoogleMap from "./GoogleMap";

const GoogleMapWrapper: FunctionComponent = ({

}) => {

    const render = (status: Status) => {
        return <h1>{status}</h1>
    }

    return (
        <Wrapper apiKey={'AIzaSyD6HNGNeeTc0faJ5F3DL5Hgn7NvUbZx2DM'} render={render}>
            <GoogleMap />
        </Wrapper>
    );
};

export default GoogleMapWrapper;