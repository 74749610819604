import * as React from 'react'
import {FunctionComponent, useEffect} from 'react'
import Layout from '../components/layout/Layout'
import {graphql, PageProps} from "gatsby";
import {Box} from "@mui/material";
import GoogleMapWrapper from "../components/GoogleMap/GoogleMapWrapper";

type DataType = {
    wpPage: {
        title: string;
        content: string;
    };
};

const App: FunctionComponent<PageProps<DataType>> = ({
    data
}) => {
    useEffect(() => {
        if (window.initSlick !== undefined) {
            window.initSlick();
        }
    }, []);

    return (
        <Layout title={'Početna'} hideTitle>
            <script src={'/wp/wp-includes/js/jquery/jquery.min.js?ver=3.6.0'}></script>
            <script src={'/wp/wp-includes/js/jquery/jquery-migrate.min.js?ver=3.3.2'}></script>
            <script src={'/slick/slick.min.js?ver=1'}></script>
            <link rel={'stylesheet'} type={'text/css'} href={'/slick/slick.min.css?ver=1'} />

            <Box component={'article'} dangerouslySetInnerHTML={{
                __html: data.wpPage.content,
            }} sx={{
                '& iframe': {
                    width: '100%',
                },
                '& .hero-image': {
                    '& > img': {
                        width: '1134px',
                        maxHeight: '647px',
                    },
                },
                '& p': {
                    fontSize: '0.875rem',
                    color: '#9a9a9a',
                },
                '& p > a': {
                    color: theme => theme.palette.common.black,
                    textDecoration: 'none',
                    fontWeight: '700',
                    fontSize: '0.875rem',
                },
            }}/>

            <script src={'/slick/view.js?ver=2'}></script>

            <GoogleMapWrapper />
        </Layout>
    )
}

export const pageQuery = graphql`
    query HomePage {
        wpPage(slug: {eq: "naslovnica"}) {
            title
            content
        }
    }
`;

export default App

export const Head = () =>
    <meta name="facebook-domain-verification" content="04rzn954r97vr95qqeucx9nr7g4jxx" />;
